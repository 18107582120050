@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

* {
  font-family: 'EB Garamond', serif !important;
  font-size: large;
}

*{
/*   background-color: rgba(255, 0, 0, 0.068); */
}

*:focus{
  outline: none !important;
}

html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0;
}

.arc-black {
  font-family: 'Archivo Black', sans-serif !important;
}

.abril {
  font-family: 'Abril Fatface', cursive !important;
}

.text-outline{
  -webkit-text-stroke: 1px black;
  color: transparent;
}
.text-outline-white{
  -webkit-text-stroke: 1px white;
  color: transparent;
}

/*buttons*/
.dark-button{
  width: 200px;
  text-align: center;
  border: 1px solid black;
  padding: 8px 0px;
  background-color: black;
  color: white;
  border-radius: 1000px;
}

.transparent-button{
    width: 200px;
    text-align: center;
    border: 1px solid black;
    padding: 8px 0px;
    border-radius: 1000px;
}

.white-button{
  width: 200px;
  text-align: center;
  border: 1px solid white;
  padding: 8px 0px;
  color: black;
  background-color: white;
  border-radius: 1000px;
}

/*checkout*/
.checkout-input{
  border-radius: 10px;
  border: 1px solid #0000008f;
  padding: 3px 10px;
  margin: 10px 0px;
}

/*contant form*/
.input{
  border-bottom: 1px solid black;
  font-size: 30px;
  margin: 20px 0px;
  background: none;
}

.input-mobile{
  border-bottom: 1px solid black;
  font-size: 20px;
  margin: 20px 0px;
  background: none;
}